const EVENT_FEED_LOADING = "EVENT_FEED_LOADING";
const EVENT_FEED_FAILED = "EVENT_FEED_FAILED";
const ADD_EVENT_FEED = "ADD_EVENT_FEED";
const SET_EVENT_FEED_FILTER = "SET_EVENT_FEED_FILTER";
const CLEAR_EVENT_FEED = "CLEAR_EVENT_FEED";
export const EVENT_FEED_RETAIN_STATE = "EVENT_FEED_RETAIN_STATE";

export const fetchEventFeed = (query) => (dispatch) => {
  dispatch(feedLoading(true));

  const bearer = "Bearer " + localStorage.getItem("token");

  return fetch(process.env.REACT_APP_API_BASE_URL + "event/feed", {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      Authorization: bearer,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      dispatch(
        addEventFeed(
          response.events,
          response.moreEvents,
          response.lastFetchedIds
        )
      );
    })
    .catch((error) => {
      dispatch(feedFailed(error.message));
    });
};

export const feedLoading = () => ({
  type: EVENT_FEED_LOADING,
});

export const feedFailed = (errmess) => ({
  type: EVENT_FEED_FAILED,
  payload: errmess,
});

export const addEventFeed = (eventFeed, moreFeed, lastFetchedIds) => ({
  type: ADD_EVENT_FEED,
  payload: { eventFeed, moreFeed, lastFetchedIds },
});

export function setEventFeedFilter(value) {
  return function (dispatch) {
    dispatch(clearEvents());
    dispatch({ type: SET_EVENT_FEED_FILTER, payload: value });
  };
}

export function clearEvents() {
  return {
    type: CLEAR_EVENT_FEED,
  };
}

const initialState = {
  isFeedLoading: true,
  feedErrMess: null,
  eventFeed: [],
  moreFeed: true,
  lastFetchedIds: [],
  feedQuery: `?registrationEndDate[$gte]=${new Date().toISOString()}`,
  feedRetainState: false,
};

export default function eventFeedReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_EVENT_FEED:
      return {
        ...state,
        isFeedLoading: false,
        feedErrMess: null,
        eventFeed: [...state.eventFeed, ...payload.eventFeed],
        moreFeed: payload.moreFeed,
        lastFetchedIds: [...state.lastFetchedIds, ...payload.lastFetchedIds],
      };

    case EVENT_FEED_LOADING:
      return {
        ...state,
        isFeedLoading: true,
        moreFeed: true,
        feedErrMess: null,
      };

    case EVENT_FEED_FAILED:
      return {
        ...state,
        isFeedLoading: false,
        moreFeed: false,
        feedErrMess: payload,
      };
    case CLEAR_EVENT_FEED:
      return {
        ...state,
        eventFeed: [],
        moreFeed: true,
        lastFetchedIds: [],
        feedRetainState: false,
      };
    case SET_EVENT_FEED_FILTER:
      return {
        ...state,
        feedQuery: payload,
      };
    case EVENT_FEED_RETAIN_STATE:
      return {
        ...state,
        feedRetainState: true,
      };
    default:
      return state;
  }
}
